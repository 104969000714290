import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Hero from "../components/layout/hero"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col } from "reactstrap"

const NotFoundPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        fileName: file(relativePath: { eq: "covers/hero-index.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  
  const image = data.fileName.childImageSharp.fluid
  return (
    <Layout hideDisclaimer={true}>
      <SEO title="404: Not found" />
      <Hero
        fluidImage={image}
        title="404: Seite nicht gefunden"
        hasSearchbar={false}
      >
        <Row>
          <Col xs="12" md="auto">
            <p>
              Dieser Link existiert nicht oder ist nicht mehr aktuell.
            </p>
          </Col>
        </Row>
      </Hero>
    </Layout>
  )
}

export default NotFoundPage

