import BackgroundImage from "gatsby-background-image"
import React from "react"
import { Col, Container, Row } from "reactstrap"

const Hero: React.FC<any> = ({ title, fluidImage, hasSearchbar, children }) => {
  return (
    <BackgroundImage
      Tag="section"
      className={`hero${hasSearchbar ? ' with-search': ''}`}
      fluid={fluidImage}
      backgroundColor={`#040e18`}
      alt={title}
    >
      <Container className="container-fluid">
        <Row className="align-items-end">
          <Col xs="12" lg="11">
            <h1>{title}</h1>
            {children}
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

export default Hero
